import {Inject, Injectable, OnDestroy} from '@angular/core';
import {
    MsalBroadcastService,
    MsalGuardConfiguration,
    MsalService,
    MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import {
    AccountInfo,
    AuthenticationResult,
    EventMessage,
    EventType,
    InteractionStatus,
    RedirectRequest,
    SsoSilentRequest,
} from '@azure/msal-browser';
import {Subject} from 'rxjs';
import {
    filter,
    takeUntil,
} from 'rxjs/operators';
import {ConfigurationService} from '../config/configuration.service';
import {AppInsightsService} from '../app-insights/app-insights.service';
import {SeverityLevel} from '@microsoft/applicationinsights-web';
import {IdTokenClaims, PromptValue} from '@azure/msal-common'
import {Router} from '@angular/router';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
    acr?: string,
    tfp?: string,
};

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService implements OnDestroy {
    public get loggedIn(): boolean {
        return this.authService.instance.getAllAccounts().length > 0;
    }
    private signInPolicyName: string;
    private passwordChangePolicyName: string;
    private passwordResetPolicyName: string;
    private profileEditPolicyName: string;
    private changeSignInNamePolicyName: string;
    private authority: string;

    public account$: Subject<AccountInfo> = new Subject();
    public msalInProgress$ = this.msalBroadcastService.inProgress$;

    private readonly _destroying$ = new Subject<void>();

    public get activeAccount(): AccountInfo {
        return this.authService.instance.getActiveAccount();
    }

    constructor(
        @Inject(MSAL_GUARD_CONFIG)
        private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private insights: AppInsightsService,
        private config: ConfigurationService,
        private router: Router,
    ) {
        this.signInPolicyName = config.get('signUpSignInPolicy');
        this.passwordChangePolicyName = config.get('passwordChangePolicy');
        this.passwordResetPolicyName = config.get('passwordResetPolicy');
        this.profileEditPolicyName = config.get('profileEditPolicy');
        this.changeSignInNamePolicyName = config.get('changeSignInNamePolicy');
        this.authority = config.get('authority');

        // This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
        this.authService.instance.enableAccountStorageEvents();
        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
            )
            .subscribe(() => {
                if (this.authService.instance.getAllAccounts().length === 0) {
                    this.router.navigate(['/']);
                } else {
                    this.router.navigate(['/home']);
                }
            });

        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.checkAndSetActiveAccount();
            })

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
                    || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
                    || msg.eventType === EventType.SSO_SILENT_SUCCESS),
                takeUntil(this._destroying$)
            )
            .subscribe((result: EventMessage) => {
                const payload = result.payload as AuthenticationResult;
                const idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

                let callHandled: true;
                if (idtoken.acr === this.signInPolicyName || idtoken.tfp === this.signInPolicyName) {
                    this.authService.instance.setActiveAccount(payload.account);
                    this.account$.next(payload.account);
                    callHandled = true;
                }

                /**
                 * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting from SUSI flow.
                 */
                if (idtoken.acr === this.profileEditPolicyName || idtoken.tfp === this.profileEditPolicyName
                    || idtoken.acr === this.passwordChangePolicyName || idtoken.tfp === this.passwordChangePolicyName
                    || idtoken.acr === this.changeSignInNamePolicyName || idtoken.tfp === this.changeSignInNamePolicyName) {
                    // retrieve the account from initial sing-in to the app
                    const originalSignInAccount = this.authService.instance.getAllAccounts()
                        .find((account: AccountInfo) =>
                            account.idTokenClaims?.oid === idtoken.oid
                            && account.idTokenClaims?.sub === idtoken.sub
                            && ((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr === this.signInPolicyName
                                || (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp === this.signInPolicyName)
                        );

                    const signUpSignInFlowRequest: SsoSilentRequest = {
                        authority: `${config.get('authority')}/${config.get('signUpSignInPolicy')}`,
                        account: originalSignInAccount
                    };

                    callHandled = true;
                    console.log(JSON.stringify(signUpSignInFlowRequest));
                    // silently login again with the signUpSignIn policy
                    this.authService.ssoSilent(signUpSignInFlowRequest);
                }

                /**
                 * Below we are checking if the user is returning from the reset password flow.
                 * If so, we will ask the user to reauthenticate with their new password.
                 * If you do not want this behavior and prefer your users to stay signed in instead,
                 * you can replace the code below with the same pattern used for handling the return from
                 * profile edit flow
                 */
                if (idtoken.acr === this.passwordResetPolicyName || idtoken.tfp === this.passwordResetPolicyName) {
                    const signUpSignInFlowRequest: RedirectRequest = {
                        authority: this.authority,
                        scopes: [...this.config.get('protectedResourceMap')],
                        prompt: PromptValue.LOGIN // force user to reauthenticate with their new password
                    };
                    callHandled = true;

                    this.login(signUpSignInFlowRequest);
                }

                if (!callHandled) {
                    const message = 'MSAL: Unhandled login success for policy: ' + idtoken.acr + ' ' + idtoken.tfp + ' -- ' + this.signInPolicyName;
                    console.log(message);
                    this.insights.trackTrace(
                        {
                            message: message,
                            severityLevel: SeverityLevel.Information,
                        });
                }

                return result;
            });

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
                takeUntil(this._destroying$)
            )
            .subscribe((result: EventMessage) => {

                // Check for forgot password error
                // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
                if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
                    const resetPasswordFlowRequest: RedirectRequest = {
                        authority: this.authority,
                        scopes: [],
                    };

                    this.login(resetPasswordFlowRequest);
                };

                // Keep for reference
                // AADB2C90077: User does not have an existing session and request prompt parameter has a value of '{0}'.
                // if (result.error?.message.includes('AADB2C90077')) {
                //     this.login();
                // }

                this.insights.trackTrace(
                    {
                        message: 'Unhandled MSAL error: ' + JSON.stringify(result),
                        severityLevel: SeverityLevel.Information,
                    });
            });
    }

    public ngOnDestroy(): void {
        this._destroying$.next();
        this._destroying$.complete();
    }

    public changeSignin() {
        const changeSignInNameRequest = {
            scopes: ['openid'],
            authority: `${this.config.get('authority')}/${this.config.get('changeSignInNamePolicy')}`,
        };

        this.authService.loginRedirect(changeSignInNameRequest).subscribe({
            error: (error) => {
                console.error(error);
                this.insights.trackTrace({
                    message: 'MSAL: Change sign in name error. ' + JSON.stringify(error),
                    severityLevel: SeverityLevel.Error,
                });
            }
        });
    }

    public changePassword() {
        const changePasswordFlowRequest = {
            scopes: ['openid'],
            authority: `${this.config.get('authority')}/${this.config.get('passwordChangePolicy')}`,
        };
        this.authService.loginRedirect(changePasswordFlowRequest).subscribe({
            error: (error) => {
                console.error(error);
                this.insights.trackTrace({
                    message: 'MSAL: Change password error. ' + JSON.stringify(error),
                    severityLevel: SeverityLevel.Error,
                });
            }
        });
    }

    public logout() {
        this.authService.logoutRedirect().pipe(
            takeUntil(this._destroying$),
        ).subscribe({
            error: (error) => {
                console.error(error);
                this.insights.trackTrace({
                    message: 'MSAL: Logout redirect error. ' + JSON.stringify(error),
                    severityLevel: SeverityLevel.Error,
                });
            }
        });
    }

    public login(userFlowRequest?: RedirectRequest) {
        const request: RedirectRequest = this.msalGuardConfig.authRequest
            ? {...this.msalGuardConfig.authRequest} as RedirectRequest
            : userFlowRequest;

        if (!request.redirectStartPage) {
            request.redirectStartPage = window.location.href;
        }

        this.authService.loginRedirect(request).subscribe({
            error: (error) => {
                console.error(error);
                this.insights.trackTrace({
                    message: 'MSAL: Login redirect error. ' + JSON.stringify(error),
                    severityLevel: SeverityLevel.Error,
                });
            }
        });
    }

    private checkAndSetActiveAccount() {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * let activeAccount = this.authService.instance.getActiveAccount();
         * To use active account set here, subscribe to inProgress$ first in your component
         */
        let activeAccount = this.authService.instance.getActiveAccount();

        if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
            let accounts = this.authService.instance.getAllAccounts();
            this.authService.instance.setActiveAccount(accounts[0]);

            this.account$.next(accounts[0]);
        }
    }
}
